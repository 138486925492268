import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default class StickyHeader {
  constructor(headerElement) {
    this.dom = {
      header: headerElement,
      prevDirection: null,
      stateClasses: {
        up: 'state-scrolled-up',
        down: 'state-scrolled-down',
      },
    };

    this.mount();
  }

  // eslint-disable-next-line class-methods-use-this
  mount() {
    ScrollTrigger.create({
      trigger: document.documentElement,
      start: 'top top',
      end: 99999,
      onUpdate: (self) => {
        if (window.scrollY < this.dom.header.clientHeight) {
          this.dom.header.classList.remove(this.dom.stateClasses.up);
          this.dom.header.classList.remove(this.dom.stateClasses.down);
          return;
        }

        // exit on same direction)
        if (self.direction === this.dom.prevDirection) return;

        // set direction
        this.dom.prevDirection = self.direction;

        /**
         * set scrolling direction states (classes)
         * 1: down
         * -1: up
         */
        if (self.direction === 1) {
          this.dom.header.classList.remove(this.dom.stateClasses.up);
          this.dom.header.classList.add(this.dom.stateClasses.down);
        } else {
          this.dom.header.classList.remove(this.dom.stateClasses.down);
          this.dom.header.classList.add(this.dom.stateClasses.up);
        }
      },
    });
  }
}
