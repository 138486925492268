import baguetteBox from 'baguettebox.js';

export default class Lightbox {
  constructor(lightboxSelector, lightboxButton, lightboxIndex) {
    this.dom = {
      lightboxSelector,
      lightboxButton,
      lightboxIndex,
    };
    this.mount();
  }

  mount() {
    baguetteBox.run(this.dom.lightboxSelector, {});
    if (this.dom.lightboxButton) {
      this.dom.lightboxButton.addEventListener('click', () => this.showGalleryAtIndex());
    }
  }

  showGalleryAtIndex() {
    const gallery = baguetteBox.run(this.dom.lightboxSelector, {});
    baguetteBox.show(
      this.dom.lightboxIndex,
      gallery[0],
    );
  }
}
