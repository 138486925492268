import Tagify from '@yaireo/tagify';
import cities from '@/json/cities.json';

export default class TagifyFilter {
  constructor(TagifyElement) {
    this.dom = {
      TagifyElement,
    };
    this.mount();
  }

  mount() {
    // eslint-disable-next-line no-new
    new Tagify(this.dom.TagifyElement, {
      whitelist: cities,
      enforceWhitelist: true,
      userInput: true,
      backspace: false,
      dropdown: {
        position: 'all',
        appendTarget: document.getElementById('city-container'),
        classname: 'region-select',
      },
      callbacks: {
        change: (e) => this.addData(e),
      },
    });
  }

  // Function that loops through the data
  addData(e) {
    const arrZIP = JSON.parse(e.detail.value);
    this.removeHiddenInputs();
    arrZIP.forEach((zip) => {
      this.createHiddenInput(zip.id);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  createHiddenInput(zip) {
    const form = document.getElementById('form-property-search');
    const input = document.createElement('input');
    input.type = 'hidden';
    input.value = zip;
    input.name = 'City[]';
    form.appendChild(input);
  }

  // eslint-disable-next-line class-methods-use-this
  removeHiddenInputs() {
    const inputFields = document.querySelectorAll('input[name="City[]"]');
    inputFields.forEach((inputField) => {
      inputField.remove();
    });
  }
}
