export default class Video {
  constructor(videoLayout) {
    this.dom = {
      videoLayout,
      video: videoLayout.querySelector('video'),
      soundControls: videoLayout.querySelector('.sound-controls'),
    };
    this.mount();
  }

  mount() {
    if (this.dom.video) {
      this.dom.video.muted = true;
    }

    if (this.dom.soundControls) {
      this.dom.soundControls.addEventListener('click', () => this.soundToggleHandler());
    }
  }

  // Toggles the sound of the video
  soundToggleHandler() {
    this.dom.video.muted = !this.dom.video.muted;
    this.dom.soundControls.classList.toggle('video-muted');
  }
}
