import FilterForm from '@/js/components/forms/filter_form';

class Overview {
  constructor() {
    this.dom = {
      filterForm: document.getElementById('filter-form'),
    };

    this.components = {

    };

    this.mount();
  }

  mount() {
    if (this.dom.filterForm) {
      this.components.filterForm = new FilterForm(this.dom.filterForm);
    }
  }
}

if (document.getElementById('page-estate-overview')) {
  // eslint-disable-next-line no-new
  new Overview();
}
