import Swiper, { Navigation } from 'swiper/core';

// configure Swiper to use modules
Swiper.use([Navigation]);

export default class SwiperCentered {
  constructor(swiperEl) {
    this.dom = {
      swiper: swiperEl,
    };

    this.data = {
      swiperConfig: {
        loop: true,
        slidesPerView: 'auto',
        loadPrevNextAmount: 3,
        initialSlide: Math.floor([...swiperEl.querySelectorAll('.swiper-slide')].length / 2),
        spaceBetween: 20,
        centeredSlides: true,
        freeMode: true,
        grabCursor: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          992: {
            spaceBetween: 40,
          },
          1200: {
            spaceBetween: 60,
          },
        },
        on: {
          navigationprev: () => {
            this.hideDragElement();
          },
          navigationNext: () => {
            this.hideDragElement();
          },
          sliderMove: () => {
            this.hideDragElement();
          },
        },
      },
    };

    this.components = {
      swiperInstance: null,
    };

    this.mount();
  }

  hideDragElement() {
    const indicator = this.dom.swiper.querySelector('.drag-indicator');
    if (indicator) {
      indicator.classList.add('out');
    }
  }

  mount() {
    this.components.swiperInstance = new Swiper(this.dom.swiper, this.data.swiperConfig);
  }
}
