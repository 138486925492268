import share from '../components/share/share';

// share buttons
document.querySelectorAll('.js-share').forEach((shareEl) => shareEl.addEventListener('click', () => {
  share({
    title: shareEl.dataset.title,
    text: shareEl.dataset.text,
  });
}));

function requireAll(r) {
  r.keys().forEach(r);
}

// load all js files, but exclude those in partials
requireAll(require.context('./', true, /^(?!.*\/partials\/).*.js$/));
