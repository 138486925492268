import Video from '@/js/components/video/video';
import Validation from '@/js/components/forms/validation';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

class Home {
  constructor() {
    this.dom = {
      subscriberForm: document.getElementById('subscriber-form'),
      videoLayouts: [...document.querySelectorAll('.l-video')],
      stepSections: [...document.querySelectorAll('.l-step')],
    };

    this.components = {
      subscriberFormValidation: null,
    };

    this.mount();
  }

  mount() {
    if (this.dom.subscriberForm) {
      this.components.subscriberFormValidation = new Validation(this.dom.subscriberForm);
    }

    this.stepScroller();

    this.dom.videoLayouts.forEach((videoLayout) => {
      // eslint-disable-next-line no-new
      new Video(videoLayout);
    });
  }

  stepScroller() {
    this.getStepsWrapperMaxWidth();

    ScrollTrigger.addEventListener('refreshInit', () => this.getStepsWrapperMaxWidth());

    gsap.to(this.dom.stepSections, {
      x: () => `-${this.getStepsWrapperMaxWidth() - window.innerWidth}`,
      ease: 'none',
      scrollTrigger: {
        trigger: '.steps-wrapper',
        start: 'center center',
        end: () => `+=${this.getStepsWrapperMaxWidth()}`,
        pin: '#page-home',
        scrub: true,
        invalidateOnRefresh: true,
      },
    });
  }

  getStepsWrapperMaxWidth() {
    let maxWidth = 0;
    this.dom.stepSections.forEach((stepSection) => {
      maxWidth += stepSection.offsetWidth;
    });
    return maxWidth;
  }
}

if (document.getElementById('page-home')) {
  // eslint-disable-next-line no-new
  new Home();
}
