export function getLanguage() {
  return document.documentElement.lang;
}

// alias for getLanguage()
export const lang = getLanguage();

export default {
  lang,
};
