import Video from '@/js/components/video/video';
import Validation from '@/js/components/forms/validation';
import GoogleMaps from '@/js/components/google_maps/map';

class EstateDetail {
  constructor() {
    this.dom = {
      mapEl: document.getElementById('map'),
      formContact: document.getElementById('form-contact'),
      alertScroll: document.querySelector('.js-alert-scroll:not(.d-none)'),
      videoLayouts: [...document.querySelectorAll('.l-video')],
    };

    this.props = {
      mapMarkers: null,
      mapOptions: null,
      infoWindowContent: null,
    };

    this.components = {
      googleMaps: null,
      contactFormValidation: null,
    };

    this.events = {
      onMapLoaded: this.onMapLoaded.bind(this),
    };

    this.mount();
  }

  mount() {
    if (this.dom.mapEl) {
      this.initMaps();
    }

    if (this.dom.formContact) {
      this.components.contactFormValidation = new Validation(this.dom.formContact);
    }

    if (this.dom.alertScroll) {
      this.dom.alertScroll.scrollIntoView({ behavior: 'smooth' });
    }

    this.dom.videoLayouts.forEach((videoLayout) => {
      // eslint-disable-next-line no-new
      new Video(videoLayout);
    });
  }

  initMaps() {
    // parse props
    this.props.mapOptions = JSON.parse(this.dom.mapEl.dataset.options);

    if (this.dom.mapEl.dataset.markers !== undefined) {
      this.props.mapMarkers = JSON.parse(this.dom.mapEl.dataset.markers);
    }

    // listen to map loaded
    this.dom.mapEl.addEventListener('loaded', this.events.onMapLoaded);

    // create map instance
    this.components.googleMaps = new GoogleMaps(this.dom.mapEl, this.props.mapOptions);
  }

  onMapLoaded() {
    // add markers
    if (this.props.mapMarkers !== null) {
      this.props.mapMarkers.forEach((marker) => {
        this.components.googleMaps.addMarker(marker);
      });
    }
  }
}

if (document.getElementById('page-estate-detail')) {
  // eslint-disable-next-line no-new
  new EstateDetail();
}
