import axios from 'axios';

export default class FilterForm {
  constructor(formEl) {
    this.dom = {
      formEl,
      fields: [...formEl.querySelectorAll('.dropdown-menu .dropdown-item')],
      filters: [...formEl.querySelectorAll('.filter-item[data-multiselect]')],
    };

    this.states = {
      idle: 'STATE_IDLE',
      awaitingRequest: 'STATE_AWAITING_REQUEST',
    };

    this.props = {
      url: formEl.getAttribute('action') || '',
      target: formEl.dataset.target || null,
      type: null,
    };

    this.data = {
      state: this.states.idle,
    };

    this.events = {
      onFormSubmit: this.onFormSubmit.bind(this),
      onFilterClick: this.onFilterClick.bind(this),
      onRequestSuccess: this.onRequestSuccess.bind(this),
      onRequestError: this.onRequestError.bind(this),
    };

    this.mount();
  }

  mount() {
    if (this.dom.formEl) {
      this.dom.formEl.addEventListener('submit', this.events.onFormSubmit);
      this.dom.fields.forEach((field) => {
        field.addEventListener('click', this.events.onFilterClick);
      });
      this.dom.filters.forEach((filter) => {
        FilterForm.initFilterBadge(filter);
      });
    }
  }

  onFormSubmit(e = undefined) {
    if (e) e.preventDefault();

    // awaiting server response, ignore click
    if (this.data.state === this.states.awaitingRequest) return;

    if (!this.props.target) return;

    // set fields
    this.dom.fields = [...this.dom.formEl.querySelectorAll('.dropdown-menu .active')];

    // disallow form submit
    this.data.state = this.states.awaitingRequest;

    const formData = new FormData(this.dom.formEl);

    formData.append('target', this.props.target);

    this.dom.fields.forEach((field) => {
      const { name, value } = field.dataset;

      if (!name || !value) {
        return;
      }

      if (field.closest('.filter-item').dataset) {
        formData.append(`Filters[${name}][]`, value);
      } else {
        formData.append(`Filters[${name}]`, value);
      }
    });

    // send request
    axios.post(this.props.url, formData)
      .then(this.events.onRequestSuccess)
      .catch(this.events.onRequestError);
  }

  onFilterClick(e) {
    e.preventDefault();

    const { target } = e;
    const parent = target.closest('.filter-item');
    const multiselectable = (parent.dataset.multiselect !== undefined);

    target.classList.toggle('active');

    const siblings = [...target.parentNode.parentNode.children]
      .map((el) => el.firstChild)
      .filter((el) => el !== target);

    if (multiselectable === false) {
      siblings.forEach((el) => el.classList.remove('active'));
      target.classList.add('active');
      parent.querySelector('.btn-filter').innerText = target.innerText;
    }

    // set the active state of the filter + update the badge
    if ((target.closest('.dropdown-menu').getElementsByClassName('active').length > 0)) {
      FilterForm.initFilterBadge(parent);
    } else {
      parent.classList.remove('active');
    }

    this.onFormSubmit();
  }

  /**
   * request was successful
   * @param response {Object}
   */
  onRequestSuccess(response) {
    // allow form submit
    this.data.state = this.states.idle;

    Object.entries(response.data).forEach(([key, value]) => {
      if (typeof value !== 'string' && !(value instanceof String)) return;

      if (!key || Number.isInteger(parseFloat(key))) {
        return;
      }

      if (key === 'url') {
        window.history.replaceState('', '', value);
        return;
      }

      const target = document.querySelector(key);

      if (!target) return;

      target.outerHTML = value;
      target.dispatchEvent(new Event('update'));
    });
  }

  /**
   * request has failed
   * @param error {Object}
   */
  onRequestError(error) {
    console.error(error);

    // allow form submit
    this.data.state = this.states.idle;
  }

  static initFilterBadge(filter) {
    const parent = filter;
    const count = parent.querySelector('.dropdown-menu').getElementsByClassName('active').length;

    if (parent.querySelector('.badge') !== null) {
      parent.querySelector('.badge').innerHTML = count;
      if (count > 0) {
        parent.classList.add('active');
      }
    }
  }
}
