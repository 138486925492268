import axios from 'axios';

export default class Validation {
  /**
   * @param formEl {HTMLElement}
   */
  constructor(formEl) {
    this.dom = {
      formEl,
      fields: [],
      message: formEl.querySelector('.js-message'),
    };

    this.states = {
      idle: 'STATE_IDLE',
      awaitingRequest: 'STATE_AWAITING_REQUEST',
    };

    this.props = {
      url: formEl.getAttribute('action') || '',
    };

    this.data = {
      invalidClass: 'is-invalid',
      message: {
        text: null,
        class: null,
      },
      formValidationClass: 'was-validated',
      validationFeedbackClass: 'validation-feedback',
      state: this.states.idle,
      errors: null,
    };

    this.events = {
      onContactSubmit: this.onContactSubmit.bind(this),
      onValidatedInputChange: this.onValidatedInputChange.bind(this),
      onRequestSuccess: this.onRequestSuccess.bind(this),
      onRequestError: this.onRequestError.bind(this),
    };

    this.mount();
  }

  mount() {
    if (this.dom.formEl) {
      this.dom.formEl.addEventListener('submit', this.events.onContactSubmit);
    }
  }

  /**
   * form has been submitted
   * @param e {Event}
   */
  onContactSubmit(e) {
    // stay on page
    e.preventDefault();

    // awaiting server response, ignore click
    if (this.data.state === this.states.awaitingRequest) return;

    // reset message state
    this.resetMessage();

    // set fields
    this.dom.fields = [...this.dom.formEl.querySelectorAll('[name]:not([type="hidden"])')];

    // disallow form submit
    this.data.state = this.states.awaitingRequest;

    // set formData
    const formData = new FormData(this.dom.formEl);

    // send request
    axios.post(this.props.url, formData)
      .then(this.events.onRequestSuccess)
      .catch(this.events.onRequestError);
  }

  /**
   * request was successful
   * @param response {Object}
   */
  onRequestSuccess(response) {
    this.data.errors = response.data.errors || [];
    this.parseMessage(response.data.message);
    this.setMessage(); // uncomment to always show message (error or success)

    // unbind change events
    this.dom.fields.forEach((f) => f.removeEventListener('change', this.events.onValidatedInputChange));

    if (this.isInValid()) {
      // form has errors
      this.handleErrors();
    } else {
      this.setMessage();

      // form was submitted successfully
      this.handleSuccess();
    }

    // allow form submit
    this.data.state = this.states.idle;
  }

  isInValid() {
    if (this.data.errors && Object.keys(this.data.errors).length > 0) {
      return true;
    }

    return this.data.message && this.data.message.class === 'alert-danger';
  }

  /**
   * request has failed
   * @param error {Object}
   */
  onRequestError(error) {
    console.error(error);

    // allow form submit
    this.data.state = this.states.idle;
  }

  /**
   * parse message object
   * @param messageObj {Object}
   */
  parseMessage(messageObj) {
    if (!messageObj) {
      this.data.message = {
        text: null,
        class: null,
      };

      return;
    }

    switch (messageObj.type) {
      case 'error':
        this.data.message.class = 'alert-danger';
        break;
      case 'success':
        this.data.message.class = 'alert-success';
        break;
      default:
        this.data.message.class = null;
        break;
    }

    // set text
    if (messageObj.text) {
      this.data.message.text = messageObj.text;
    } else {
      this.data.message.text = null;
    }
  }

  // show message
  setMessage() {
    // if we have no message, exit fn
    if (!this.data.message.text) return;

    // apply classes
    if (this.data.message.class) {
      this.dom.message.classList.add(this.data.message.class);
    }

    // apply text
    this.dom.message.textContent = this.data.message.text;

    // show message
    this.dom.message.classList.remove('d-none');

    // scroll to message
    this.dom.message.scrollIntoView({ behavior: 'smooth' });
  }

  // empty message and hide it
  resetMessage() {
    if (!this.dom.message) {
      return;
    }
    // reset class
    this.dom.message.classList.add('d-none');
    if (this.data.message.class) {
      this.dom.message.classList.remove(this.data.message.class);
    }
    // reset text
    this.dom.message.textContent = '';
  }

  /**
   * validated input has changed
   * @param e {Event}
   */
  onValidatedInputChange(e) {
    const field = e.target;
    const validationFeedback = field.closest(`.${this.data.validationFeedbackClass}`);

    // remove invalid state
    // set error class
    if (validationFeedback) {
      validationFeedback.classList.remove(this.data.invalidClass);
    } else {
      field.classList.remove(this.data.invalidClass);
    }

    // unbind change event
    field.removeEventListener('change', this.events.onValidatedInputChange);
  }

  // form was submitted successfully
  handleSuccess() {
    // hide validation states
    this.dom.formEl.classList.remove(this.data.formValidationClass);

    // reset form
    this.dom.formEl.reset();
  }

  // form has errors
  handleErrors() {
    for (const [key, value] of Object.entries(this.data.errors)) {
      // get element that has an error
      const field = this.dom.fields.find((f) => f.name === key);
      if (field) {
        const validationFeedback = field.closest(`.${this.data.validationFeedbackClass}`);
        let invalidFeedback = field.parentNode.querySelector('.invalid-feedback');

        // set error class
        if (validationFeedback) {
          invalidFeedback = validationFeedback.querySelector('.invalid-feedback');
          validationFeedback.classList.add(this.data.invalidClass);
        } else {
          field.classList.add(this.data.invalidClass);
        }

        // set error
        if (invalidFeedback) {
          invalidFeedback.innerHTML = value.toString();
        }

        // bind change event
        field.addEventListener('change', this.events.onValidatedInputChange);
      }
    }

    // show errors
    this.dom.formEl.classList.add(this.data.formValidationClass);
  }
}
