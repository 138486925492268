import SwiperCentered from '../../components/swiper/centered';

const widgetInstances = document.querySelectorAll('.widget--carousel');

widgetInstances.forEach((widget) => {
  const swiperEl = widget.querySelector('.swiper');

  // eslint-disable-next-line no-new
  if (swiperEl) new SwiperCentered(swiperEl);
});
