import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(SplitText);

export default class SplitTitle {
  constructor(SplitTitleElement) {
    this.dom = {
      SplitTitleElement,
    };
    this.mount();
  }

  mount() {
    const splitTextChars = new SplitText(this.dom.SplitTitleElement, {
      type: 'chars, words',
      charsClass: 'char',
    }).chars;

    gsap.set(this.dom.SplitTitleElement, { perspective: 400 });

    gsap.from(splitTextChars, {
      scrollTrigger: {
        trigger: this.dom.SplitTitleElement,
        once: true,
        markers: false,
        start: 'top bottom',
      },
      opacity: 0,
      scale: 0,
      y: 80,
      rotationX: 180,
      transformOrigin: '0% 50% -50',
      duration: 0.8,
      stagger: 0.01,
      ease: 'back',
    });
  }
}
