import 'share-api-polyfill';
import * as Toast from 'bootstrap/js/dist/toast';

const toastEl = document.getElementById('toast-share');
let toast;

if (toastEl) {
  toast = new Toast(toastEl, {
    delay: 4000,
  });
}

function getLang(fallbackLang = 'en') {
  const { lang } = document.documentElement;

  if (lang) return lang;
  return fallbackLang;
}

export default function share({ title = '', text = '', url = window.location.href }) {
  navigator.share(
    {
      title,
      text,
      url,
    },
    {
      copy: false,
      // email: true,
      // print: true,
      // sms: true,
      // messenger: true,
      // facebook: true,
      // whatsapp: true,
      // twitter: true,
      // linkedin: true,
      // telegram: true,
      // skype: true,
      // pinterest: true,
      language: getLang(),
    },
  )
    .then(() => {
      if (toast) {
        toastEl.classList.remove('bg-danger');
        toastEl.classList.add('bg-success');
        toast.show();
      }
    })
    .catch(() => {
      if (toast) {
        toastEl.classList.remove('bg-success');
        toastEl.classList.add('bg-danger');
        toast.show();
      }
    });
}
