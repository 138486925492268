import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default class Autoplay {
  constructor(video) {
    this.dom = {
      video,
    };

    this.mount();
  }

  mount() {
    ScrollTrigger.create({
      trigger: this.dom.video,
      onToggle: (self) => (self.isActive ? this.play() : this.pause()),
    });
  }

  play() {
    this.dom.video.play();
  }

  pause() {
    this.dom.video.pause();
  }
}
