import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default class FadeIn {
  constructor(FadeInElement) {
    this.dom = {
      FadeInElement,
    };
    this.mount();
  }

  mount() {
    gsap.fromTo(this.dom.FadeInElement, {
      autoAlpha: 0,
    }, {
      scrollTrigger: {
        trigger: this.dom.FadeInElement,
        once: true,
        markers: false,
        start: '20px 90%',
      },
      duration: 2,
      autoAlpha: this.dom.FadeInElement.dataset.fadeOpacity,
    });
  }
}
