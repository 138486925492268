// components
import Validation from '@/js/components/forms/validation';
import TagifyFilter from '@/js/components/tagify/tagify';

class PropertySubscribe {
  constructor() {
    this.dom = {
      formContact: document.getElementById('form-property-search'),
      alertScroll: document.querySelector('.js-alert-scroll:not(.d-none)'),
      tagifyElements: document.querySelectorAll('[data-tagify]'),
    };

    this.components = {
      contactFormValidation: null,
    };

    this.mount();
  }

  mount() {
    this.initTagify();

    if (this.dom.formContact) {
      this.components.contactFormValidation = new Validation(this.dom.formContact);
    }

    if (this.dom.alertScroll) {
      this.dom.alertScroll.scrollIntoView({ behavior: 'smooth' });
    }
  }

  initTagify() {
    const instances = [];

    this.dom.tagifyElements.forEach((el) => {
      if (instances.includes(el)) return;
      instances.push(new TagifyFilter(el));
    });
  }
}

if (document.getElementById('page-property-subscribe')) {
  // eslint-disable-next-line no-new
  new PropertySubscribe();
}
