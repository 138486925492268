import CookiesNotice from '@/js/components/cookies/cookies';

const cookiesNoticeEl = document.getElementById('cookies-notice');
const cookiesNoticeModal = document.getElementById('cookie-customizer-modal');

// Cookies notice
if (cookiesNoticeEl || cookiesNoticeModal) {
  // eslint-disable-next-line no-new
  new CookiesNotice(cookiesNoticeEl, cookiesNoticeModal);
}
